<template>
	<router-link :to="routerLink">
		<div class="blog-card" :style="{'background-image': 'url(' + image + ')'}">
			<div class="title">{{title}}</div>
		</div>
	</router-link>
</template>

<script>
	import helpers from '@/helpers/helpers.js';
    export default {
        name: 'TallBlogCard',
		props: {
			title: {
				type: String,
				default: "Test Title"
			}, 
			img: {
				type: String,
			},
			id: {
				type: String,
			},
			routerLinkCategory: {
				type: Object,
				default: { name: 'Community Stories' }
			}
		},
		methods: {
			formatDate: (date) => helpers.formatDate(date),
			getImage: (img, includesExtension) => helpers.getImage(img, includesExtension),
		},
		computed: {
			routerLink() {
				let routerLink = this.routerLinkCategory;
				routerLink.params = { id: this.id }
				return routerLink;
			},
			image() {
				if(this.img) {
					return this.img
				} else {
					return this.getImage('banners/placeholder.webp', true);
				}
			}
		},
    }
</script>

<style scoped>
.blog-card {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    border: none;
	background-repeat: no-repeat;
	background-size: cover;
}
.blog-card .title {
	font-weight: bold;
	font-size: 20px;
	color: #FFFFFF;
	position: absolute;
	bottom: 15px;
	left: 15px;
	line-height: 28px;
	padding-right: 15px;
}
</style>

<template>
	<div id="mission" class="outer-wrapper"> 
		<Loader :loading="isLoading" :error="error" @refresh="init()"/>

		<div class="content" v-if="!isLoading && !error">
			<div class="inner-wrapper"> 
				<div class="heading-section">
					<h1 class="heading3" v-if="data.fields.heading">{{ data.fields.heading }}</h1>
					<div class="intro" v-if="data.fields.subheading">
						{{ data.fields.subheading }}
					</div>
					<img v-if="data.fields.top_image" :src="`${data.fields.top_image}`" class="main-image"/>
					<div class="sub-heading" v-if="data.fields.intro_heading">
						{{ data.fields.intro_heading }} 
					</div> 
					<div v-if="data.fields.intro_text" v-html="data.fields.intro_text" class="text">
					</div>
				</div>
			</div>
			<div class="main">
				<div v-if="data.fields.feature_image" class="feature-image" :style="{ 'background-image': `url(${data.fields.feature_image})` }">
					<div class="text" v-if="data.fields.feature_image_text">
						{{ data.fields.feature_image_text }}
					</div>
				</div>
			</div>
			<div class="inner-wrapper"> 
				<div class="main">
					<div class="main-inner">
						<div class="top">
							<div v-if="data.fields.paragraph_top" v-html="data.fields.paragraph_top" class="text">
							</div>
						</div>
						<div class="cards-inner" v-if="data.fields.card.length > 0 && (data.fields.card[0].heading || data.fields.card[0].text)">
							<div class="card" v-for="(card, n) in data.fields.card" :key="n">
								<img :src="`${card.icon}`" class="icon"/>
								<div class="sub-heading" v-if="card.heading">
									{{ card.heading }}
								</div>
								<div v-html="card.text" class="text">
								</div>
							</div>
						</div>
						<div class="bottom">
							<div v-if="data.fields.paragraph_bottom" v-html="data.fields.paragraph_bottom" class="text">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="sliders" v-if="showBlogs">
				<TallBlogCardSlider
					v-bind:slider_title="sliders.blog_slider_title"
					v-bind:slides_data="blogCardsData"
					v-bind:viewAllRouterLink="{ name: 'Community Stories' }"
					v-bind:viewCardRouterLink="{ name: 'Blog Article' }"
					:isLoading="blogsLoading"
				/>
			</div>
		</div>
	</div><!-- end of outer wrapper -->
</template>


<script>

import TallBlogCardSlider from '@/components/sliders/TallBlogCardSlider.vue'
import { mapState, mapActions } from 'vuex';
import contentService from '@/services/contentService.js';
import Loader from '@/components/Loader.vue';

export default {
	props: {
		pageIDProp: { // The page ID to load. Only checks for this prop after checking the route param. This prop allows us to define specific routes without using the dynamic route /page/:id.
			default: null
		},
		showBlogs: {
			type: Boolean,
			default: true
		}
	},
    data () {
        return {
			error: false,
			isLoading: true,
			blogsLoading: true,

			data: false,
			sliders: {
				blog_slider_title: "Community Stories",
			},
        }
	},
	components: {
		TallBlogCardSlider,
		Loader
	},
    computed: {
		...mapState({
			alert: state => state.alert,
			blogs: state => state.blogs.blogs
		}),
		blogCardsData() {
			var blogCards = [];
			for(let index in this.blogs) {
				let ele = this.blogs[index];
				blogCards.push({
					id: ele.id,
					title: ele.fields.heading,
					img: ele.fields.featured_image,
				});
			}
			return blogCards;
		}
    },
    methods: {
		...mapActions({
			fetchBlogs: "blogs/fetchBlogs"
		}),
		fetchPosts: function() {
			this.blogsLoading = true;
			if(this.blogs.length == 0) {
				this.isLoading = true;
			}
			this.fetchBlogs()
				.then((response) => {
					this.blogsLoading = false;
					this.blogsError = false;
				},
				error => {
					this.blogsError = error;
					this.isLoading = false;
				});
		},
		fetchPageData() {
			this.isLoading = true;
			if(this.pageIDProp !== null) {
				contentService.getPage(this.pageIDProp)
					.then(response => {
						if(response.data.data.template == "our-mission") {
							this.data = response.data.data;
							this.isLoading = false;
						} else {
							this.error = "Page not found.";
							this.isLoading = false;
						}
					},
					error => {
						this.error = error;
						this.isLoading = false;
					});
			} else {
				this.error = "Page not found.";
				this.isLoading = false;
			}
		},
		init() {
			this.data = null;
			if(this.showBlogs) {
				this.fetchPosts();
			}
			this.fetchPageData();
		},
		createdHook() {
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					route: this.$route.query.redirect ? this.$route.query.redirect : { name: 'Home' },
				},
				right: {
					type: 'menu',
				},
			});

			this.init();
		},
	},
	watch: {
		'$route' (to, from) {
			this.createdHook();
		}
	},
	created() {
		this.createdHook();
	},
};
</script>


<style scoped>
	#mission.inner-wrapper {
		font-family: 'Poppins';
	}
	.sub-heading, #mission .intro {
		font-size: 18px;
		font-weight: 700;
		margin: 28px auto 47px auto;
	}
	.sub-heading {
		margin: 44px auto 60px auto;
	}
	#mission .heading3, #mission .intro {
		font-family: 'Inter', sans-serif;
		text-align: left;
	}
	#mission .heading-section, #mission .main {
		text-align: left;
	}
	.main-image {
		width: 100%;
		height: 292px;
		object-fit: cover;
		border-radius: 10px;
		display: block;
		margin: 0 auto;
	}
	.main .feature-image {
		position: relative;
		width: 640px;
		height: 320px;
		background-size: cover;
		background-position: center;
		margin: 40px auto;
		border-radius: 4px;
	}
	#mission .text p {
		font-size: 14px;
		line-height: 24px;
		font-family: 'Inter';
	}
	.main .feature-image .text {
		position: absolute;
		bottom: 0;
		width: 100%;
		max-height: 100%;
		overflow: hidden;
		font-size: 14px;
		line-height: 21px;
		font-family: 'Poppins';
		font-weight: 700;
		color: #fff;
		padding: 15px 30px;
		box-sizing: border-box;
	}
	.main .cards-inner {
		margin: 75px auto 22px auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		column-gap: 30px;
	}
	.main .card {
		width: 325px;
		/* min-height: 227px; */
		text-align: center;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 27px 24px 15px 24px;
		box-sizing: border-box;
		margin-bottom: 30px;
	}
	.main .card:last-of-type {
		margin-bottom: 0;
	}
	.main .card .sub-heading {
		font-size: 20px;
		margin: 8px 0px;
	}
	.main .card .icon {
		height: 60px;
		width: auto;
	}
	.main .card .text {
		font-size: 14px;
	}
	.main .bottom {
		margin-bottom: 87px;
	}	
	#mission .sliders .swiper-slide {
		width: 30%;
	}
	#mission .big-card-slider .title-container {
		margin: 20px auto;
		padding: 0;
	}
	.big-card {
		background-size: cover;
	}
	
	@media screen and (max-width: 959px) {
		#mission .heading3, #mission .intro {
			text-align: center;
		}
		.sub-heading, #mission .intro {
			max-width: 270px;
		}
		.sub-heading {
			margin: 34px auto 22px auto;
		}
		#mission .intro {
			margin: 28px auto 60px auto;
		}
		.heading-section .sub-heading {
			text-align: center;
			margin: 34px auto 22px auto;
		}
		.main-image {
			width: 100%;
			height: auto;
			object-fit: cover;
			border-radius: 10px;
			display: block;
			margin: 0 auto;
		}
		.main .feature-image {
			width: 100%;
			height: 240px;
			margin: 30px auto;
			border-radius: 0;
		}
		.main .feature-image .text {
			padding: 23px;
		}
		#mission .text p {
			font-size: 20px;
		}
		.main .card {
			width: 301px;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
		}
		.main .cards-inner {
			margin: 8px auto 52px auto;
			flex-direction: unset;
			flex-wrap: wrap;
		}
		.main .bottom {
			margin-bottom: 48px;
		}
	}
	@media screen and (max-width: 720px) {
		.sub-heading, #mission .intro {
			margin: 24px auto;
		}
		.main-image {
			width: 330px;
			height: 178px;
		}
	}
	@media screen and (max-width: 400px) {
		.main-image, .main .card {
			width: 300px;
		}
	}
</style>
<template>
	<div>
		<div class="blog-card-slider" v-if="slides_data.length > 0 || isLoading">
			<div class="title-container">
				<span class="slider-title">{{slider_title}}</span>
				<router-link :to="viewAllRouterLink">
					<button class="button tiny">see all</button>
				</router-link>
			</div>
			<swiper ref="big-card-slider" :options="swiperOptions" class="destinations-card-slider">
				<swiper-slide v-for="(data, index) in slides_data" :key="index">
					<TallBlogCard 
						v-bind:title="data.title"
						v-bind:routerLinkCategory="viewCardRouterLink"
						v-bind:img="data.img"
						:id="data.id" 
					/>
				</swiper-slide>
				<!-- <div class="swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next" slot="button-next"></div> -->
			</swiper>
		</div>  
		<Loader :loading="isLoading"/>
	</div>  
</template>

<script>
import TallBlogCard from '@/components/sliders/TallBlogCard.vue'
import Loader from '@/components/Loader'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
	name: 'TallBlogCardSlider',
	props: {
		slider_title: {
			type: String,
			default: ""
		}, 
		slides_data: {
			type: Array,
			// Array of Objects in format: [{
			//	 id: 1
			//	 title: "Title",
			//	 img: "big-card-slider",
			// }]
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		// The Router Link data for the 'See all' button in child component
		viewAllRouterLink: {
			type: Object,
			default: {
				name: 'Community Stories'
			}
		},
		// The Router Link data for when the user clicks an individual card (child component). Exact ID param gets added in child component
		viewCardRouterLink: {
			type: Object,
			default: {
				name: 'Blog Article'
			}
		}
	},
	data() {
		return {
			swiperOptions: {
				slidesPerView: "auto",
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			},
		}
	},
	components: {
		TallBlogCard,
		Loader,
		Swiper,
		SwiperSlide
	},
	directives: {
		swiper: directive
	},
}
</script>

<style scoped>
	.destinations-card-slider {
		margin-bottom: 50px;
	}
	.destinations-card-slider .swiper-slide {
		width: 240px;
		height: 450px;
		margin-right: 15px;
	}
	.destinations-card-slider .swiper-slide:first-of-type {
		margin-left: 15px;
	}
	.blog-card-slider .slider-title {
		font-weight: 500;
		font-size: 18px;
		line-height: 28px;
		margin-left: 15px;
	}
	.blog-card-slider .button {
		color: #118AB2;
		background-color: transparent;
		border-radius: 0px;
		font-size: 16px;
		line-height: 20px;
		font-weight: 500;
	}
	.blog-card-slider .title-container {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin: 20px auto;
	}
	.blog-card-slider .button:hover {
		text-decoration: underline;
	}
	
	@media screen and (max-width: 720px) {
		.destinations-card-slider .swiper-slide {
			width: 200px;
			height: 300px;
			margin-left: 20px;
		}
	}
</style>
